import { Spin } from 'antd';
import { MainLayout } from '@components/Layout';
import { lazyImport } from '@utils/lazyImport';
import { Suspense, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useToken } from 'hooks/useToken';
import { AUTH_URL } from '@configs';
import { UserContext } from '@providers';

const { QuesionRoutes } = lazyImport(
  () => import('@features/questions'),
  'QuesionRoutes'
);
const { AnswerRoutes } = lazyImport(
  () => import('@features/answers'),
  'AnswerRoutes'
);
const { TagRoutes } = lazyImport(() => import('@features/tags'), 'TagRoutes');
const { ActivityRoutes } = lazyImport(
  () => import('@features/activities'),
  'ActivityRoutes'
);
const { TransactionStatusRoutes } = lazyImport(
  () => import('@features/transaction-statuses'),
  'TransactionStatusRoutes'
);

const { UserRoutes } = lazyImport(
  () => import('@features/users'),
  'UserRoutes'
);

const { AdminUserRoutes } = lazyImport(
  () => import('@features/admin-users'),
  'AdminUserRoutes'
);

const { SystemConfigRoutes } = lazyImport(
  () => import('@features/system-configs'),
  'SystemConfigRoutes'
);

const { CategoryBargainRoutes } = lazyImport(
  () => import('@features/category-bargains'),
  'CategoryBargainRoutes'
);

const { ChannelRoutes } = lazyImport(
  () => import('@features/channels'),
  'ChannelRoutes'
);

const { EpicRoutes } = lazyImport(
  () => import('@features/epics'),
  'EpicRoutes'
);

const { ScriptRoutes } = lazyImport(
  () => import('@features/scripts'),
  'ScriptRoutes'
);

const { ConditionRoutes } = lazyImport(
  () => import('@features/conditions'),
  'ConditionRoutes'
);

const { TenantRoutes } = lazyImport(
  () => import('@features/tenants'),
  'TenantRoutes'
);

const { ProjectRoutes } = lazyImport(
  () => import('@features/projects'),
  'ProjectRoutes'
);

const { ScriptRoutes: TScriptRoutes } = lazyImport(
  () => import('@features/tscripts'),
  'ScriptRoutes'
);

const App = () => {
  const
    { token, clearToken, tokenIsExpired, refreshTokenIsExpired } = useToken(),
    { fetchUserInfo } = useContext(UserContext),
    loginUrl = `${AUTH_URL}?redirect_uri=${window.location.origin}`;

  if (!token) {
    window.location.href = loginUrl;
    return;
  }

  if (tokenIsExpired() && refreshTokenIsExpired()) {
    clearToken();
    setTimeout(() => {
      window.location.href = loginUrl;
    }, 3000);

    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3>
          Phiên làm việc đã hết hạn. Vui lòng <a href={loginUrl}>đăng nhập</a>{' '}
          lại.
        </h3>
        <h3>Hệ thống tự động thoát sau 3 giây</h3>
      </div>
    );
  }

  useEffect(() => {
    let timer1 = setTimeout(function () {
      window.postMessage({
        type: 'ICA_AUTH',
        payload: token,
      }, '*');
    }, 3000);
    return () => {
      clearTimeout(timer1)
    }
  }, [])

  useEffect(() => {
    if (token) {
      fetchUserInfo();
    }
  }, [token])
  
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spin size="large" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const privateRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'questions/*', element: <QuesionRoutes /> },
      { path: 'answers', element: <AnswerRoutes /> },
      { path: 'tags', element: <TagRoutes /> },
      { path: 'activities', element: <ActivityRoutes /> },
      { path: 'transaction-statuses', element: <TransactionStatusRoutes /> },
      { path: 'users', element: <UserRoutes /> },
      { path: 'admin-users', element: <AdminUserRoutes /> },
      { path: 'system-configs', element: <SystemConfigRoutes /> },
      { path: 'bargains/*', element: <CategoryBargainRoutes /> },
      { path: 'channels', element: <ChannelRoutes /> },
      { path: 'epics', element: <EpicRoutes /> },
      { path: 'scripts', element: <ScriptRoutes /> },
      { path: 'conditions', element: <ConditionRoutes /> },
      { path: 'tenants', element: <TenantRoutes /> },
      { path: 'projects', element: <ProjectRoutes /> },
      { path: 'tscripts/*', element: <TScriptRoutes /> },
    ],
  },
];
